import React from 'react'
import "../components/Divit.css"

const Divit = () => {
  return (
    <div className='container-star'>
<div className='box' id="box1">
    <h3>1</h3>
</div>
    </div>
  )
}

export default Divit