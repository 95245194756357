import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const res = await axios.get('http://localhost:8080/register.php');
    setUsers(res.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post('http://localhost:8080/register.php', formData);
    fetchUsers();
    setFormData({ name: '', email: '', phone: '' });
  };

  const handleDelete = async (id) => {
    await axios.delete(`http://localhost:8080/register.php?id=${id}`);
    fetchUsers();
  };

  return (
    <div className="App">
      <h1>CRUD with React and PHP</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="name" 
          value={formData.name} 
          onChange={handleChange} 
          placeholder="Name" 
          required 
        />
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleChange} 
          placeholder="Email" 
          required 
        />
        <input 
          type="text" 
          name="phone" 
          value={formData.phone} 
          onChange={handleChange} 
          placeholder="Phone" 
          required 
        />
        <button type="submit">Add User</button>
      </form>

      <h2>Users</h2>
      <ul>
        {users.map(user => (

<>
          <li key={user.id}>
            {user.name} - {user.email} - {user.phone}
            <button onClick={() => handleDelete(user.id)}>Delete</button>
          </li>
          
          </>
        ))}
       
      </ul>
      <p></p>
    </div>
  );
  
};

export default App;
